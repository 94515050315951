document.addEventListener("DOMContentLoaded", function() {
    filterGraveyards();
    handleMobileMenu();
});

function filterGraveyards() {
    const graveyardNameInput = document.querySelector("#graveyard-finder");

    if (!graveyardNameInput) return;

    graveyardNameInput.addEventListener("keyup", function() {
        let graveyardNameValue = graveyardNameInput.value.toLowerCase();
        let graveyardList = document.querySelectorAll(".graveyards-graveyard");

        graveyardList.forEach(function(graveyard) {
            let graveyardName = graveyard.dataset.name.toLowerCase();

            if (graveyardName.includes(graveyardNameValue)) {
                graveyard.style.display = "block";
            } else {
                graveyard.style.display = "none";
            }
        });
    });
}

function handleMobileMenu() {
    const menuToggle = document.querySelector('.header-menu-toggle');
    const navWrapper = document.querySelector('.header-nav-wrapper');
    const navLinks = document.querySelectorAll('.header-nav a'); // Pobieramy wszystkie linki w menu

    // Funkcja do otwierania/zamykania menu
    function toggleMenu() {
        navWrapper.classList.toggle('open');
    }

    // Otwieranie/zamykanie menu po kliknięciu w przycisk "Menu"
    menuToggle.addEventListener('click', toggleMenu);

    // Zamykamy menu po kliknięciu w dowolny link w menu
    navLinks.forEach(link => {
        link.addEventListener('click', function() {
            // Jeśli menu jest otwarte, zamykamy je
            if (navWrapper.classList.contains('open')) {
                toggleMenu();
            }
        });
    });
}
